export  default {

    computed: {
        collectable_type(){
            return String(this.form.collectable_type || '' );
        },

        collectable(){
            return (this.form || {}).collectable || {};
        },
        collectable_component(){
            if ( this.collectable_type.search(/Program/)>-1 ){
                return 'program-collectable';
            }
            return 'div';
        },
    }
}

