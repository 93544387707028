<template>
  <div class="col-span-full md:col-span-4 md:col-start-3 mt-10">

    <div class="text-center">


      <success-tick-icon></success-tick-icon>

      <h4 class="text-lg  mb-8 text-green-600">
        <p>
          Your Online admission form already <span class="font-bold text-green-400">successfully</span> submitted
        </p>
        <p>
          with Phone <span class="font-bold text-green-400">{{phone}}</span>
        </p>
        <p class="text-blue-600 mt-4">You dont need to submit again.</p>
      </h4>
      <p v-if="payableAmount > 0" class="text-lg">
        Do you want to continue with payment now?
      </p>
    </div>

    <div class="flex justify-between mt-10 mb-8 gap-4" >
      <btn variant="warning-outline" router-path="/">Homepage</btn>
      <btn type="submit" variant="success" v-if="payableAmount > 0">Yes</btn>
      <btn type="button" router-path="/courses" variant="success-outline" v-else>Courses</btn>
    </div>

  </div>
</template>

<script>
import SuccessTickIcon from "./SuccessTickIcon";
export default {
  name: "SubmissionSuccess",
  components: {SuccessTickIcon},
  props: {
    phone:String,
    payableAmount: Number,
  }
}
</script>

<style scoped>

</style>