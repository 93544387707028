<template>
	<div>
    <container>

      <div class="pb-4" v-if="!is_submitted_step">
        <p class="text-left text-lg">{{form.name}}</p>

        <component class="grid-items-center text-xs" :is="collectable_component" :collectable="collectable" show-price>
        </component>
      </div>

      <div class="my-10 text-center" v-if="is_thankyou_step">
        <thank-you :hidden-phone="studentPhone"
                   :form="this.form"
                   @otp-verified="otpVerified"
        />
      </div>

      <div class="text-center border  bg-white shadow-lg flex justify-center items-center" v-else-if="is_submitted_step">
        <submission-success :phone="phone_number" :payable-amount="0"></submission-success>
      </div>

      <div class="border p-16 my-10 text-center" v-else-if="is_otp_verified_step">
        <api-verified :token="token" ></api-verified>
      </div>


      <form @submit.prevent="submit" v-else>

        <div class="grid grid-cols-8 border p-4">

          <data-collection-field :field="{
                                    placeholder: 'Type your phone',
                                    label:'Phone',
                                    type: 'text',
                                    is_mandatory: true,
                                    focus: true
                                 }"
                                 ref="phone_input"
                                 v-model="phone_number"
                                 @input="phoneChange"
                                 :key="inputName('phone')"
                                 :name="inputName('phone')"
                                 :focus="phone_focus"
                                 class="col-span-full py-4"
          >
          </data-collection-field>

          <template v-if="!phone_has_form_data">

              <data-collection-field v-for="field in mandatory_fields" :field="field" :key="field.label"
                                     :name="`${field.name}`"
                                     class="col-span-full py-4"></data-collection-field>

              <data-collection-field v-for="field in fields" :field="field" :key="field.id"
                                     class="col-span-full py-4"  :name="`${form.expire_in}-${field.id}`" />

          </template>

          <template v-else>
            <submission-success :phone="phone_number" :payable-amount="payable_amount"></submission-success>
          </template>

        </div>

        <div class="text-red-800 text-sm py-4">
          <p v-for="err in allErrors" class="py-1" v-html="err"></p>
        </div>

        <div class="mt-4" v-if="!phone_has_form_data">
          <btn type="submit" size="lg" variant="success" :disabled="submissionDisabled">Submit</btn>
          <span class="ml-4">{{message}}</span>
        </div>

      </form>


    </container>

	</div>

</template>

<script>

import Container from "../../layouts/container";
import InputText from "../../../components/input/input-text";
import DataCollectionField from "./data-collection-field";
import Btn from "../../../components/btn";
import axios from "../../../store/axios";
import ThankYou from "./components/thank-you.vue";
import PaymentConfirmation from "./components/api-verified.vue";
import ApiVerified from "./components/api-verified";
import collectableMixins from "./mixins/collectable-mixins";
import ProgramCollectable from "./components/program-collectable";
import SuccessTickIcon from "./components/SuccessTickIcon";
import SubmissionSuccess from "./components/SubmissionSuccess";

export default {
	name: "data-collections",
  emits: ['previousRecord'],
  mixins: [ collectableMixins ],
	components: {
    SubmissionSuccess,
    SuccessTickIcon,
    ProgramCollectable,
      ApiVerified,
      Btn,
      DataCollectionField,
      InputText,
      Container,
      ThankYou,
      PaymentConfirmation
  },
	data() {
    return {
      phone_number:'',
      studentPhone: localStorage.getItem('_form_hidden_phone'),
      form: {},
      fields: [ ],
      form_data:{
        Phone: ''
      },
      form_type:0,
      message: '',
      errors: {},
      formSuccessfullySubmitted: false,
      phone_has_form_data: false,
      form_disabled: true,
      isPhoneVerified: false,
      otpSuccessfullySubmitted: false,
      token: null,
      previous_form_item_id: null,
      payable_amount: 0,
      phone_focus: true
    }
	},
  mounted() {
    this.phone_focus = !!true;
  },
  computed: {

    is_thankyou_step(){
      return this.$route.query.step=='thank-you' && (localStorage.getItem('_form_hidden_phone') || this.formSuccessfullySubmitted)
    },
    is_submitted_step(){
      return this.$route.query.step=='submitted';
    },
    is_otp_verified_step(){
      return this.$route.query.step=='otp-verified';
    },
    id(){
      return this.$route.params.id
    },
    submissionDisabled(){
      if( this.form_disabled ) {
        return true;
      } else  {
        return this.phone_has_form_data;
      }

    },
    allErrors(){
      let errs = [];


      Object.values( this.errors )
          .forEach( er=> errs = errs.concat( er ) );

      return errs;
    },
    mandatory_fields(){

      return [
        {
          label: "Name",
          is_mandatory: true,
          placeholder: "Type your name",
          type: "text",
          name: `${this.form.expire_in}-name`
        },

        {
          searchable: false,
          label: "Gender",
          is_mandatory: true,
          html_tag: "select",
          placeholder: 'Select Gender',
          type: "select",
          name: `${this.form.expire_in}-gender`,
          dropdown_items: [
            {
              id:1,
              name:'Male',
            },
            {
              id: 2,
              name:'Female'
            }
          ],
        }

      ]
    }
	},
  created() {

    this.$store.dispatch( 'data_collections/show', { id: this.id } ).then( ({data}) => {
      this.form =  typeof data == 'object' ? data : {};

      const dataCollectionData = data;
      this.form_type = data.type;
      this.payable_amount = this.form_type == 1 ? data.collectable.price:data.payable_amount;

      console.log({dataCollectionData});
      // this.collectable = this.form.collectable || {};
      // this.collectable_type = this.form.collectable_type || '';
      this.fields = Array.isArray( this.form.fields ) ? this.form.fields : [ ];

    });

    this.token = localStorage.getItem( `_otp_verification_token` );

  },
  methods: {
    previousRecord( form_item_id ){

    },
    inputName( name ){
      return `${this.form.expire_in}-${name}`
    },
    phoneChange( e ){
      console.log( e.target.value );

      const phone = String(e.target.value);

      console.log( 'dd', this.form );


      this.form_disabled = false;
      this.phone_has_form_data = false;

      if( phone.replace(/^\+88|0088/, '').length === 11 ) {
        this.form_disabled = true;
        this.phone_has_form_data = false;


        axios()
          .get(`data-collection-forms/${this.form.id}/check-phone`, {
            params: { phone: phone.replace(/^\+88|0088/, '') }
          }).then( ({data}) => {

            this.studentPhone = data.hidden_phone;
            this.previous_form_item_id = data.form_item_id;

            localStorage.setItem('_form_hidden_phone', this.studentPhone );
            localStorage.setItem('_form_item_id', this.previous_form_item_id );

            this.phone_has_form_data = data.phone_has_form_data;

            if( !this.phone_has_form_data ) {
              this.form_disabled = false;
            }
        }).catch(() => {
          this.form_disabled = false;
          this.phone_has_form_data = false;
        });

      }

    },
    processData( data ){
      this.message = data.message;

      if( !data.success ) {
        this.errors = typeof data.errors == 'object' ? data.errors:[];
      } else {
        this.studentPhone = data.hidden_phone;
        localStorage.setItem( '_form_hidden_phone', data.hidden_phone );
        localStorage.setItem( '_form_item_id', data.form_item_id );
        if( this.payable_amount > 0 ) {
          this.$extendRouteQueryParams({ step: "thank-you" } );
        }else {
          this.$extendRouteQueryParams({ step: "submitted" } );
        }
        this.formSuccessfullySubmitted = true;
      }

    },
    tryAnother( ){
      this.formSuccessfullySubmitted=false;
      this.errors = [];
      this.message = '';
      this.phone_has_form_data = '';

      this.phone_number = '';
      this.phone_focus = false;
      this.phone_focus = true;

      this.$extendRouteQueryParams({});
    },
    submit(e){

      if( !this.phone_has_form_data && !this.form_disabled ) {
        const formData = new FormData(  e.target );

        console.log(this.form_data, formData);

        formData.append('__security', this.form.expire_in)
        formData.append('__form_id', this.form.id)



        axios().post('data-collection-forms', formData, {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        })
            .then(({data}) => this.processData(data))
            .catch(({response}) => this.processData((response || {data:{ success:false, message: 'Unknown error' }}).data));

      } else {

        this.$emit( 'previousRecord', this.previous_form_item_id );
        if( this.previous_form_item_id ) {
          this.resendBtnDisabled = true;

          axios().post(`data-collection-forms/${this.form.id}/resend-otp`, {
            form_item_id: this.previous_form_item_id
          }).then(({data}) => {

            this.formSuccessfullySubmitted = true;
            localStorage.setItem( '_form_hidden_phone', this.studentPhone );
            //localStorage.setItem( '_form_item_id',  this.previous_form_item_id );
            this.studentPhone = data.hidden_phone;

            this.$extendRouteQueryParams({ step: "thank-you", 'previous-record': 'true' } );
          }).catch(() =>{
            this.resendBtnDisabled = false;
          });

        }

      }

    },
    otpVerified( data ){
      this.otpSuccessfullySubmitted = true;
      this.token = data.token;
    }
  }

};
</script>

<style scoped>

</style>
