<template>
    <section class="text-gray-600 body-font">
        <form @submit.prevent="agree_to_pay" class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">

            <div class="text-center lg:w-2/3 w-full">
                <h2 class="sm:text-xl text-base text-green-600 font-medium title-font text-gray-900">You phone has been verified</h2>
                <p class="mb-8 leading-relaxed text-base">Do you want to pay now?</p>

                <div class="flex justify-between">
                    <btn router-path="/" variant="warning-outline">No</btn>
                    <btn type="submit" variant="success">Yes</btn>
                </div>

            </div>
        </form>
    </section>


</template>

<script>
    import Btn from "../../../../components/btn";
    import axios from "../../../../store/axios";
    export default {
      components: {Btn},
      props: { token: {type:String, default: null}},
      computed: {
        token(){
          return this.$route.query.token;
        }
      },
      methods: {
        agree_to_pay(){


          console.log("OO");

          this.$router.push({
            path: '/payment/choose-method',
            query: {
              step: 'payment',
              token: this.token,
            }
          });

        }
      }
    }
</script>