<template>
    <section class="text-gray-600 body-font relative">
        <div class="container px-5 py-4 md:py-24 mx-auto">
            <div class="flex flex-col text-center w-full md:mb-12">
                <h2 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Thanks for your response</h2>
                <p class="w-full mx-auto leading-relaxed text-base">An OTP has been sent to your phone</p>
                <p class="w-full  mx-auto leading-relaxed text-base">{{ hiddenPhone  }}</p>
            </div>


            <form @submit.prevent="submitOtp" class="lg:w-1/2 md:w-2/3 mx-auto">
                <div class="flex flex-wrap -m-2">
                    <div class="p-2 w-full">
                        <div class="relative">
                            <p class="text-center">Enter your OTP</p>
                            <input type="text"
                                   id="otp"
                                   name="otp"
                                   autocomplete="off"
                                   v-model="otp"
                                class="text-center w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <btn variant="info">
                            Confirm
                        </btn>
                    </div>

                </div>
            </form>

            <div class="mt-10 w-full">

                <span>
                    <span>Didn't get OTP?  </span>
                    <template v-if="resendBtnDisabled && countDown > 0">
                        <span>Resend after </span>
                        <span class="text-purple-600" id="time" style="font-weight:800;font-size:25px;">
                            {{ countDown }}
                        </span>s
                    </template>
                </span>

                <btn variant="info-outline" class="ml-1 " size="xs" @click.prevent="resendOtp" :disabled="resendBtnDisabled">
                    Resend OTP
                </btn>
            </div>

        </div>
    </section>



</template>

<script>

    import axios from "../../../../store/axios";
    import ApiVerified from "./api-verified";

    const OTP_COUNTDOWN_TIME = 120;

    export default {
      emits: ['otpVerified','otpResent'],
      components: {ApiVerified},
      data(){
        return {
            countDown: OTP_COUNTDOWN_TIME,
            resendBtnDisabled: true,
            otp: ''
        }
        },
        props: {
            hiddenPhone: {
              type: String,
              default: ''
            },
          form_item_id: {
              type: Number,
              default: '',
              required: true
            },
            form: {
              type: Object,
              default: {},
              required: true
            }
        },
        created () {


          this.countDownTimer()
        },
        computed: {

        },
        methods: {

            submitOtp(  ) {
              const form_item_id = localStorage.getItem('_form_item_id' );

              if( form_item_id ) {
                axios().post(`data-collection-forms/${this.form.id}/otp`, {
                  code: this.otp, form_item_id
                }).then(({data}) => {

                  if( !this.$route.query['previous-record']) {
                    this.$extendRouteQueryParams({ step: "otp-verified", token: data.token });
                    this.$emit('otpVerified', data );
                  } else {

                    this.$router.push({
                      path: '/payment/choose-method',
                      query: {
                        step: 'payment',
                        token:  data.token,
                      }
                    });

                  }

                });
              }

            },
            resendOtp() {
              const form_item_id = localStorage.getItem('_form_item_id' );

              if( form_item_id ) {
                this.resendBtnDisabled = true;
                axios().post(`data-collection-forms/${this.form.id}/resend-otp`, {
                  form_item_id
                }).then(({data}) => {
                  this.$emit('otpResent', data )
                  this.countDown = OTP_COUNTDOWN_TIME;
                  this.countDownTimer();
                }).catch(() =>{
                  this.resendBtnDisabled = false;
                });
              }

            },
            countDownTimer () {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }
                else{
                    this.resendBtnDisabled =false;
                }

            },
            
        },
    }
</script>