<template>

  <div>
      <label class="font-semibold">
        <span class="mr-1 text-gray-700">
          {{field.label}}
        </span>
        <span class="text-red-400" v-if="field.is_mandatory">*</span>
      </label>

      <component :is="input_component" :focus="focus" v-bind="input_props" ref="input" v-model="inputValue"></component>
  </div>

</template>

<script>
import InputText from "../../../components/input/input-text";
import InputDropdown from "../../../components/input/input-dropdown";
import modelValueMixins from "../../../mixins/model-value-mixins";
export default {
  name: "data-collection-field",
  components: { InputText, InputDropdown },
  props: ['field','modelValue','name','focus'],
  mixins:[modelValueMixins],
  data(){
    return {
      input_props: {},
    }
  },
  computed: {
    inputValue:{
      set(value){
        this.$emit('update:modelValue', value);
      },
      get(){
        return this.modelValue;
      }
    },
    input_component(){

      this.input_props = {
        name: this.name
      };

      // if( this.input_props.focus ) {
      //   this.input_props.autofocus = this.field.autofocus || false;
      // }

      this.input_props.focus = this.field.focus || false;

      console.log({field: this.field})

      switch (this.field.html_tag ) {
        case 'select':
          this.input_props.items = this.field.dropdown_items;

          if(Array.isArray( this.input_props.items )) {

            this.input_props.items = this.input_props.items.map( (item) => {

              if( typeof item === 'string' || typeof item === 'number') {
                return {id: item, name: item}
              } else {
                return item;
              }

            });

          } else {
            this.input_props.remoteSearchUrl = this.field.remote_url;
          }

          if( this.field.searchable === false ) {
            this.input_props.searchable = false;
          } else {
            this.input_props.searchable = true;
          }

          this.input_props.label = this.field.placeholder;
          return 'input-dropdown';
        case 'radio':

          return 'input';
        default :
          this.input_props.placeholder = this.field.placeholder;
          this.input_props.type = this.field.input_type_text;
          return 'input-text';
      }
    }
  }

}
</script>

<style scoped>

</style>