<template>
  <div class="flex justify-center mb-4">
    <div class="rounded-full w-12 h-12 border-4 border-green-400 text-green-400 leading-10 text-3xl text-center">
      &#10004;
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessTickIcon"
}
</script>

<style scoped>

</style>