<template>
  <div class="w-full grid grid-cols-2 gap-2 items-start justify-between">
    <slot name="grid-1">
      <div class="text-lg font-semibold col-span-full">
        <div class="text-gray-400 text-xs mt-1 ">Program</div>
        <div class="text-blue-400">{{collectable.display_title}}</div>
      </div>
    </slot>

    <slot name="grid-2">
      <div class=" col-span-full">
        <div class="text-gray-400 text-xs mt-1">Course</div>
        <div class="text-blue-600 text-lg">{{collectable.batch.course.name}}</div>
      </div>
    </slot>

    <slot name="grid-3">
      <div>
        <div class="text-gray-400 text-xs mt-1">Session</div>
        <div>{{collectable.batch.year}} </div>
        <div class="text-gray-400 text-xs mt-1">Branch</div>
        <div>{{collectable.service_point.branch.name}}</div>
        <div class="text-gray-400 text-xs mt-1">Service Point</div>
        <div>{{collectable.service_point.name}}</div>
      </div>
    </slot>


    <slot name="grid-6" v-if="showPrice">
      <div>
        <div class="text-gray-400 text-xs mt-1">Fee</div>
        <money-amount class="text-lg font-semibold text-blue-400" :amount="collectable.amount"/>
        <template v-if="collectable.total_discount">
          <div class="text-gray-400 text-xs mt-1">Discount</div>
          <money-amount class="text-lg font-semibold text-blue-400" :amount="collectable.total_discount"/>

          <div class="text-gray-400 text-xs mt-1">Total Payable</div>
          <money-amount class="text-lg font-semibold text-blue-400" :amount="collectable.amount - collectable.total_discount"/>
        </template>
      </div>
    </slot>

  </div>
</template>

<script>
import MoneyAmount from "../../../../components/money-amount";
export default {
  name: "program-collectable",
  components: {MoneyAmount},
  props: ['collectable','showPrice'],

}
</script>

<style scoped>

</style>